import * as React from "react";
import { Component } from "react";

class VersionControl extends Component {
  render() {
    const theme = this.props.theme;
    const dotColor = theme.imageHighlight;
    const shirtColor = theme.imageHighlight;
    const skinTone = "#ffdbac"; // ffb6b6
    const hairColor = "#8d5524"; // theme.dark

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="1000" //637.748
        height="600"
        viewBox="0 0 1000 600"
      >
        <g data-name="Group 100" fill="#e4e4e4">
          <path
            data-name="Path 1465"
            d="M561.877 471.613a11.49 11.49 0 1 0 11.49 11.49 11.49 11.49 0 0 0-11.49-11.49Zm0 18.561a7.071 7.071 0 1 1 7.071-7.071 7.071 7.071 0 0 1-7.07 7.071Z"
          />
          <path
            data-name="Path 1467"
            d="M517.684 462.774h44.193v43.739a5.757 5.757 0 0 1-5.757 5.757h-32.679a5.757 5.757 0 0 1-5.757-5.757v-43.739Z"
          />
        </g>
        <path
          data-name="Path 944"
          d="M665.133 513.271H.896a1.006 1.006 0 0 1 0-2h664.237a1.006 1.006 0 0 1 0 2Z"
          fill="#cacaca"
        />
        <path
          fill={skinTone}
          d="m89.41 494.381-4.108 20.313 76.393 25.514 6.064-29.98-78.349-15.847z"
        />
        <path
          d="M97.09 490.542 89 530.544l-.001.002a26.01 26.01 0 0 1-30.65 20.336l-.828-.168 13.247-65.496Z"
          fill="#2f2e41"
        />
        <circle
          data-name="Ellipse 276"
          cx={175.979}
          cy={162.713}
          r={51.871}
          fill={skinTone}
        />
        <path
          data-name="Path 1461"
          d="M224.427 117.963a59.907 59.907 0 0 0-34.08-26.052l-6.369 4.647v-6.034a55.143 55.143 0 0 0-10.3-.6l-5.494 4.971v-4.558a59.335 59.335 0 0 0-40.932 24.347c-11.93 17.2-13.945 41.129-2.21 58.467 3.221-9.9 7.131-19.189 10.353-29.088a29.3 29.3 0 0 0 7.633.037l3.92-9.145 1.094 8.758c12.148-1.058 30.166-3.382 41.682-5.511l-1.12-6.719 6.7 5.583c3.528-.812 5.623-1.549 5.45-2.112 8.565 13.808 19.047 22.627 27.611 36.435 3.253-19.47 6.997-35.659-3.938-53.426Z"
          fill={hairColor}
        />
        <ellipse
          data-name="Ellipse 260"
          cx={192.856}
          cy={464.787}
          rx={133.56}
          ry={37.359}
          fill="#2f2e41"
        />
        <path
          d="M287.54 296.101c-1.784-10.731-3.624-21.668-8.612-31.335-3.277-6.332-8.48-12.366-15.495-13.618a14.99 14.99 0 0 1-4.034-.934c-2.056-.99-29.674-16.756-34.063-19.502-3.77-2.357-9.72-6.722-12.72-6.722-3.017-.067-14.59 2.627-59.76-.904a163.141 163.141 0 0 0-22.01 10.822c-.145-.096-46.882 25.001-48.637 24.935-3.326-.14-6.416 1.989-8.323 4.67-1.905 2.681-2.8 6-3.735 9.21 10.208 22.732 19.547 45.494 29.756 68.227a5.821 5.821 0 0 1 .739 2.8 6.834 6.834 0 0 1-1.27 2.8c-5.007 8.042-4.848 18.157-4.3 27.61.547 9.451 1.223 19.435-3 27.907-1.15 2.326-2.644 4.455-3.736 6.781-2.56 5.268-3.484 22.117-1.99 27.777l187.267 5.365C269.59 430.922 287.539 296.1 287.539 296.1Z"
          fill={shirtColor}
        />
        <path
          data-name="Path 1421"
          d="M53.963 341.958a33.063 33.063 0 0 0-.112 8.154l2.684 38.546c.252 3.633.5 7.257.841 10.881.644 7.033 1.606 14.01 2.8 20.977a3.736 3.736 0 0 0 3.83 3.633c11.814 2.5 24.021 2.4 36.08 1.719 18.4-1.027 65.473-2.97 68.35-6.706s1.2-9.779-2.55-12.8-65.872-10.386-65.872-10.386c.607-4.81 2.438-9.34 4.175-13.907 3.12-8.1 6.034-16.466 6.09-25.143s-3.213-17.8-10.32-22.771c-5.848-4.081-13.376-4.838-20.5-4.67-5.185.149-14.142-1.093-18.877.934-3.747 1.65-5.919 7.87-6.619 11.539Z"
          fill={skinTone}
        />
        <path
          data-name="Path 1423"
          d="M287.871 429.091c3.11-1.186 6.538-.757 9.872-.308 10.732 1.466 21.622 2.97 31.57 7.257 4.67 2 9.115 4.67 12.272 8.6 2.8 3.54 4.436 7.874 5.987 12.142l3.587 9.826a47.3 47.3 0 0 1 3.13 11.516c1.12 11.759-6.922 22.845-17.037 28.954s-22.023 8.284-33.624 10.563-23.247 4.8-34.8 7.472a140.086 140.086 0 0 1-16.373 3.222c-12.366 1.4-25.292-.663-37.042 3.428-4.67 1.635-9.256 4.063-14.1 5.193a87.745 87.745 0 0 1-9.75 1.485l-22.239 2.54a121.313 121.313 0 0 1-13.636 1.046c-9.713 0-19.268-2.3-28.7-4.6a7.258 7.258 0 0 1-2.8-1.13c-1.494-1.177-1.82-3.26-2.036-5.146q-1.429-12.525-2.5-25.077c-.224-2.718-.392-5.66 1.093-7.948 1.868-2.8 5.511-3.68 8.836-4.222a230.58 230.58 0 0 1 42.665-2.961c5.426-5.426 14.374-5.454 21.482-8.406a63.897 63.897 0 0 0 7.575-4.119 99 99 0 0 1 41.59-13.141 38.714 38.714 0 0 0 8.51-1.214c5.5-1.709 10.516-6.361 16.166-5.221.7-1.933 1.186-4.521 2.8-5.847.8-.682 1.793-1.139 2.531-1.868 1.56-1.578 1.7-4.063 1.466-6.267s-.757-4.464-.187-6.613a9.14 9.14 0 0 1 1.037-2.307c2.792-4.832 7.023-7.316 12.655-6.849Z"
          fill="#2f2e41"
        />
        <path
          data-name="Path 1430"
          d="M69.61 273.414a9.667 9.667 0 0 0-2.073 3.316 156.78 156.78 0 0 0-13.169 53.816 5.38 5.38 0 0 1-.607 2.606 11.403 11.403 0 0 1-1.373 1.485 5.156 5.156 0 0 0 .617 7.266q.127.107.261.206c1.541-2.9 5.23-3.848 8.51-4.1 15.7-1.242 31.035 6.062 46.782 5.511-1.11-3.839-2.709-7.528-3.615-11.413-4.007-17.251 5.987-36.033-.14-52.649-1.224-3.325-3.269-6.594-6.49-8.051a17.29 17.29 0 0 0-4.157-1.1c-3.979-.719-11.9-3.792-15.747-2.559-1.42.458-1.98 1.793-3.157 2.6-1.788 1.142-4.16 1.562-5.642 3.066Z"
          fill={shirtColor}
        />
        <path
          data-name="Path 1421"
          d="M287.651 330.419c-4.735-2.027-13.692-.785-18.876-.934-7.125-.168-14.653.589-20.5 4.67-7.108 4.971-10.377 14.094-10.32 22.771s2.97 17.043 6.09 25.143c1.736 4.567 3.567 9.097 4.174 13.907 0 0-62.12 7.365-65.87 10.386s-5.427 9.064-2.55 12.8 92.614 7.487 104.43 4.987a3.736 3.736 0 0 0 3.828-3.633c1.194-6.967 2.156-13.944 2.8-20.977.341-3.624.59-7.248.841-10.881l2.684-38.546a33.064 33.064 0 0 0-.112-8.154c-.7-3.669-2.872-9.889-6.619-11.539Z"
          fill={skinTone}
        />
        <path
          data-name="Path 1430"
          d="M272.981 270.348c-1.177-.807-1.737-2.142-3.157-2.6-3.847-1.233-11.768 1.84-15.747 2.559a17.29 17.29 0 0 0-4.156 1.1c-3.222 1.457-5.267 4.726-6.49 8.051-6.128 16.616 3.866 35.398-.14 52.649-.907 3.885-2.505 7.574-3.616 11.413 15.747.551 31.083-6.753 46.783-5.511 3.28.252 6.968 1.2 8.51 4.1q.133-.099.26-.206a5.156 5.156 0 0 0 .617-7.266 11.401 11.401 0 0 1-1.373-1.485 5.38 5.38 0 0 1-.607-2.606 156.78 156.78 0 0 0-13.169-53.816 9.667 9.667 0 0 0-2.073-3.316c-1.482-1.504-3.854-1.924-5.642-3.066Z"
          fill={shirtColor}
        />
        <path
          fill={skinTone}
          d="m299.243 523.378-2.73 20.543-80.538-.755 4.03-30.32 79.238 10.532z"
        />
        <path
          d="m319.853 520.785-8.804 66.24-.838-.111a26.01 26.01 0 0 1-22.356-29.209v-.001l5.377-40.457Z"
          fill="#2f2e41"
        />
        <path
          data-name="Path 1427"
          d="M109.77 439.487c0 .934-1.467 0-1.6-1-1.028-7.556-7.025-13.786-14.01-16.812s-14.945-3.185-22.51-2.466c-11.44 1.083-24.284 5.464-28.468 16.158-.99 2.5-10.264 24.76-9.359 29.766 2.438 13.356 11.572 24.779 22.64 32.69s24 12.637 36.9 16.9a639.95 639.95 0 0 0 104.607 24.984c5.753.869 11.563 1.672 17.073 3.521 4.931 1.653 9.545 4.128 14.365 6.071 11.208 4.5 23.266 6.108 35.211 7.687 2.036.262 4.371.448 5.875-.934 1.943-1.8 1.3-4.941.747-7.528a40.488 40.488 0 0 1 4.773-28.729c2-3.362 4.6-6.809 4.222-10.7-.448-4.539-4.969-7.752-9.47-8.481s-9.079.346-13.6.934a7.285 7.285 0 0 1-4.624-.458 9.235 9.235 0 0 1-1.812-1.606 20.482 20.482 0 0 0-23.854-3.334c-1.672.934-4.2-1.149-5.94-1.933l-19.642-8.892c-22.565-10.208-45.242-20.464-69.115-26.927a14.514 14.514 0 0 1-5.324-2.214c-1.186-.934-2.036-2.2-3.129-3.241-2.382-2.251-5.7-3.148-8.555-4.745s-6.056-5.508-5.402-8.711Z"
          fill="#2f2e41"
        />
        <circle cx={160.617} cy={411.99} r={14} fill={skinTone} />
        <circle cx={191.617} cy={411.99} r={14} fill={skinTone} />
        <path
          d="M278.035 443.537H85.697a8.48 8.48 0 0 1-8.47-8.47V318.381a8.48 8.48 0 0 1 8.47-8.47h192.338a8.48 8.48 0 0 1 8.47 8.47v116.686a8.48 8.48 0 0 1-8.47 8.47Z"
          fill="#3f3d56"
        />
        <circle
          data-name="Ellipse 263"
          cx={181.399}
          cy={376.724}
          r={5.604}
          fill="#fff"
        />
        <path
          d="M868.03 210.452H618.136a5.006 5.006 0 0 1-5-5v-47.1h2v47.1a3.003 3.003 0 0 0 3 3h249.892a3.003 3.003 0 0 0 3-3v-47.429h2v47.43a5.006 5.006 0 0 1-5 5Z"
          fill="#3f3d56"
        />
        <path fill="#3f3d56" d="M371.397 157.352h587.22v2h-587.22z" />
        <circle
          data-name="Ellipse 264"
          cx={371.439}
          cy={158.196}
          r={17.202}
          fill={dotColor}
        />
        <circle
          data-name="Ellipse 266"
          cx={546.244}
          cy={158.196}
          r={17.202}
          fill={dotColor}
        />
        <circle
          data-name="Ellipse 268"
          cx={664.876}
          cy={208.757}
          r={17.202}
          fill="#3f3d56"
        />
        <circle
          data-name="Ellipse 269"
          cx={755.063}
          cy={208.757}
          r={17.202}
          fill="#ccc"
        />
        <circle
          data-name="Ellipse 270"
          cx={711.36}
          cy={158.196}
          r={17.202}
          fill={dotColor}
        />
        <circle
          data-name="Ellipse 271"
          cx={956.259}
          cy={158.196}
          r={17.202}
          fill={dotColor}
        />
        <circle
          data-name="Ellipse 272"
          cx={872.029}
          cy={158.196}
          r={17.202}
          fill={dotColor}
        />
        <path
          data-name="Rectangle 207"
          fill="#3f3d56"
          d="M648.539 0h31.906v21.99h-31.906z"
        />
        <path
          data-name="Rectangle 208"
          fill={dotColor}
          d="M355.612 0h31.906v21.99h-31.906z"
        />
        <path
          data-name="Rectangle 209"
          fill="#ccc"
          d="M463.685 0h31.906v21.99h-31.906z"
        />
        <path
          d="M424.782 159.352a1 1 0 0 1-1-1V108.99a5.006 5.006 0 0 1 5-5h48.675a1 1 0 0 1 0 2h-48.675a3.003 3.003 0 0 0-3 3v49.362a1 1 0 0 1-1 1Z"
          fill="#3f3d56"
        />
        <circle
          data-name="Ellipse 265"
          cx={479.744}
          cy={104.616}
          r={17.202}
          fill="#e6e6e6"
        />
        <path
          d="M809.782 158.352h-2V108.99a5.006 5.006 0 0 1 5-5h48.675v2h-48.675a3.003 3.003 0 0 0-3 3Z"
          fill="#3f3d56"
        />
        <circle
          data-name="Ellipse 265"
          cx={863.744}
          cy={104.616}
          r={17.202}
          fill="#e6e6e6"
        />
      </svg>
    );
  }
}

export default VersionControl;
