import React, { Component } from "react";
/* svgR has dropped some elements not supported by react-native-svg: title */

export default class ClusterImg extends Component {
  render() {
    const theme = this.props.theme;
    const clusterColor = theme.dark;
    const shoeColor = theme.dark;
    const pantsColor = theme.dark;
    const shirtColor = theme.imageHighlight;
    const jacketColor = theme.jacketColor;
    const laptopColor = "#3f3d56";
    const glowColor = theme.imageHighlight;
    const skinTone = "#ffdbac"; // ffb6b6
    const hairColor = "#8d5524"; // theme.dark
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="945"
        height="583.224"
        viewBox="0 0 945 583.224"
      >
        <path
          data-name="Path 438"
          d="M95.385 535.136a24.215 24.215 0 0 0 23.383-4.118c8.19-6.875 10.758-18.196 12.846-28.682l6.18-31.017-12.938 8.909c-9.304 6.406-18.818 13.018-25.26 22.297s-9.252 21.947-4.078 31.988"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 439"
          d="M97.383 574.847c-1.629-11.864-3.304-23.88-2.16-35.872 1.015-10.649 4.264-21.048 10.879-29.579a49.206 49.206 0 0 1 12.625-11.44c1.262-.797 2.424 1.203 1.167 1.997a46.78 46.78 0 0 0-18.504 22.325c-4.029 10.246-4.676 21.416-3.982 32.3.42 6.583 1.31 13.122 2.206 19.653a1.198 1.198 0 0 1-.808 1.422 1.163 1.163 0 0 1-1.423-.808Z"
          fill="#f2f2f2"
        />
        <path
          data-name="Path 442"
          d="M109.104 555.81a17.825 17.825 0 0 0 15.531 8.02c7.864-.374 14.418-5.86 20.317-11.071l17.452-15.409-11.55-.553c-8.306-.398-16.826-.77-24.738 1.794s-15.207 8.726-16.654 16.915"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 443"
          d="M92.78 581.667c7.84-13.872 16.932-29.288 33.18-34.216a37.026 37.026 0 0 1 13.956-1.44c1.482.127 1.112 2.41-.367 2.284a34.398 34.398 0 0 0-22.272 5.892c-6.28 4.274-11.17 10.217-15.308 16.519-2.535 3.86-4.805 7.884-7.076 11.903-.726 1.284-2.847.357-2.113-.942Z"
          fill="#f2f2f2"
        />
        <path
          data-name="Path 442"
          d="M880.857 551.651a17.825 17.825 0 0 1-17.065 3.783c-7.508-2.37-12.441-9.35-16.813-15.895l-12.934-19.358 11.307 2.418c8.132 1.738 16.465 3.555 23.459 8.056s12.472 12.323 11.777 20.61"
          fill="#e6e6e6"
        />
        <path
          data-name="Path 443"
          d="M890.03 580.82c-4.033-15.414-8.884-32.642-23.333-41.559a37.026 37.026 0 0 0-13.124-4.96c-1.465-.255-1.691 2.048-.23 2.303a34.398 34.398 0 0 1 20.027 11.388c4.979 5.738 8.187 12.733 10.578 19.883 1.464 4.38 2.63 8.85 3.799 13.316.373 1.428 2.661 1.073 2.284-.37Z"
          fill="#f2f2f2"
        />
        <path
          d="M731.16 355.093H466.834a7.97 7.97 0 0 1-7.962-7.962v-89.17a7.97 7.97 0 0 1 7.962-7.961h264.324a7.97 7.97 0 0 1 7.962 7.962v89.17a7.97 7.97 0 0 1-7.962 7.96Z"
          fill={clusterColor}
        />
        <path fill={glowColor} d="M500.274 273.885h70.062v7.962h-70.062z" />
        <circle cx={662.69} cy={278.662} r={4.777} fill={glowColor} />
        <circle cx={678.613} cy={278.662} r={4.777} fill={glowColor} />
        <circle cx={694.536} cy={278.662} r={4.777} fill={glowColor} />
        <path
          d="M731.16 468.147H466.834a7.97 7.97 0 0 1-7.962-7.962v-89.17a7.97 7.97 0 0 1 7.962-7.96h264.324a7.97 7.97 0 0 1 7.962 7.96v89.17a7.97 7.97 0 0 1-7.962 7.962Z"
          fill={clusterColor}
        />
        <path fill={glowColor} d="M500.274 386.939h70.062v7.962h-70.062z" />
        <circle cx={662.69} cy={391.716} r={4.777} fill={glowColor} />
        <circle cx={678.613} cy={391.716} r={4.777} fill={glowColor} />
        <circle cx={694.536} cy={391.716} r={4.777} fill={glowColor} />
        <path
          d="M731.16 581.201H466.834a7.97 7.97 0 0 1-7.962-7.961v-89.17a7.97 7.97 0 0 1 7.962-7.961h264.324a7.97 7.97 0 0 1 7.962 7.961v89.17a7.97 7.97 0 0 1-7.962 7.961Z"
          fill={clusterColor}
        />
        <path fill={glowColor} d="M500.274 499.993h70.062v7.962h-70.062z" />
        <circle cx={662.69} cy={504.77} r={4.777} fill={glowColor} />
        <circle cx={678.613} cy={504.77} r={4.777} fill={glowColor} />
        <circle cx={694.536} cy={504.77} r={4.777} fill={glowColor} />
        <path
          d="m631.512 162.927-.293-.216-.007-.006a3.576 3.576 0 0 0-5.003.74l-59.496 80.105h-6.545v-1.538a1.43 1.43 0 0 0-1.43-1.43h-3.906a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.261v-1.538a1.43 1.43 0 0 0-1.43-1.43h-3.906a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.253v-1.538a1.43 1.43 0 0 0-1.431-1.43h-3.905a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.261v-1.538a1.43 1.43 0 0 0-1.43-1.43h-3.906a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.254v-1.538a1.43 1.43 0 0 0-1.43-1.43h-3.905a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.261v-1.538a1.43 1.43 0 0 0-1.43-1.43h-3.906a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.26v-1.538a1.43 1.43 0 0 0-1.431-1.43h-3.906a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.253v-1.538a1.43 1.43 0 0 0-1.43-1.43h-3.906a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.26v-1.538a1.43 1.43 0 0 0-1.431-1.43h-3.906a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.253v-1.538a1.43 1.43 0 0 0-1.43-1.43h-3.906a1.43 1.43 0 0 0-1.43 1.43v1.538h-2.833a3.576 3.576 0 0 0-3.576 3.576v3.412a3.576 3.576 0 0 0 3.576 3.576h96.762a5.33 5.33 0 0 0 5.217-4.247l.037.028 60.871-81.958.006-.007a3.577 3.577 0 0 0-.74-5.003Z"
          fill={laptopColor}
        />
        <path
          fill={skinTone}
          d="m255.504 550.825 12.019 6.151 29.443-43.432-17.738-9.078-23.724 46.359z"
        />
        <path
          d="m254.446 545.332 23.67 12.113h.001a16.946 16.946 0 0 1 7.364 22.805l-.25.49-38.755-19.833Z"
          fill={shoeColor}
        />
        <path
          fill={skinTone}
          d="M394.317 567.652h13.502l6.423-52.077h-19.927l.002 52.077z"
        />
        <path
          d="M390.873 563.244h26.59a16.946 16.946 0 0 1 16.945 16.944v.55l-43.534.002ZM331.163 142.873s-9.041-6.927-9.041 9.993l-1.428 55.912 15.941 52.82 9.28-17.13-3.808-37.117ZM427.877 295.804s10.485 51.058-2.795 92.96l-7.34 161.107-26.909-2.097-9.436-119.52-9.086-59.41-18.871 54.168-62.207 114.627-28.656-22.366s31.756-86.65 55.216-104.842L329.56 276.42Z"
          fill={pantsColor}
        />
        <circle
          cx={523.48}
          cy={202.314}
          r={30.683}
          transform="rotate(-61.337 326.185 230.622)"
          fill={skinTone}
        />
        <path
          d="M393.809 50.434c3.678.478 6.452-3.285 7.74-6.764s2.266-7.528 5.464-9.407c4.37-2.568 9.96.52 14.955-.327 5.642-.956 9.31-6.936 9.598-12.651s-1.987-11.213-4.218-16.482l-.78 6.549A12.987 12.987 0 0 0 420.893 0l1.004 9.61a10.199 10.199 0 0 0-11.733-8.44l.158 5.726c-6.516-.775-13.09-1.551-19.626-.96s-13.117 2.658-18.071 6.962c-7.41 6.439-10.116 17.04-9.208 26.815s4.944 18.957 9.149 27.827c1.058 2.232 2.521 4.75 4.975 5.035 2.204.255 4.221-1.588 4.907-3.698a13.404 13.404 0 0 0-.06-6.585c-.62-3.295-1.401-6.662-.818-9.963s2.957-6.564 6.285-6.969 6.734 3.4 5.134 6.346Z"
          fill={hairColor}
        />
        <path
          fill={shirtColor}
          d="m429.776 309.458-102.419-6.608 7.709-31.937 93.608 20.925 1.102 17.62z"
        />
        <path
          d="m366.614 85.77 6.424-8.918s7.18 2.44 26.41 12.011l1.358 8.357 33.379 205.301-60.671-2.617-16.522-.351-5.407-12.12-6.667 11.863-16.134-.344-16.417-9.517 16.179-49.488 5.234-45.206-8.09-42.59s-10.179-39.108 29.028-60.194Z"
          fill={jacketColor}
        />
        <path
          d="M503.363 241.185a10.486 10.486 0 0 1-13.041-9.406l-35.995-9.64 14.48-12.858 31.961 11.127a10.543 10.543 0 0 1 2.595 20.777Z"
          fill={skinTone}
        />
        <path
          d="M487.241 235.742a6.142 6.142 0 0 1-4.987.396l-59.213-22.474a63.227 63.227 0 0 1-34.032-28.684l-24.693-44.05a19.765 19.765 0 1 1 29.491-26.322l42.74 67.741 53.787 31.535a6.155 6.155 0 0 1 2.617 6.546l-2.675 11.334a6.143 6.143 0 0 1-1.866 3.15 6.074 6.074 0 0 1-1.169.828Z"
          fill={jacketColor}
        />
        <path d="M944 583H1a1 1 0 0 1 0-2h943a1 1 0 0 1 0 2Z" fill="#cbcbcb" />
      </svg>
    );
  }
}
