import * as React from "react";
import { Component } from "react";

class Resume extends Component {
  render() {
    const theme = this.props.theme;
    const accentColor = theme.imageHighlight;
    const jacketColor = theme.imageHighlight;
    const skinTone = "#ffdbac"; // ffb6b6
    const hairColor = "#8d5524"; // theme.dark

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width={800}
        height={600}
        viewBox="0 0 500 600"
      >
        <path
          d="M224.994 273.305a127 127 0 1 1 127-127 127.144 127.144 0 0 1-127 127Zm0-252a125 125 0 1 0 125 125 125.141 125.141 0 0 0-125-125Z"
          fill="#f2f2f2"
        />
        <path
          d="M234.686 174.095h81.433a1 1 0 0 0 0-2h-81.433a1 1 0 0 0 0 2Z"
          fill={jacketColor}
        />
        <path
          d="M336.408 171.095a25.667 25.667 0 0 0-25.638 25.637v108.725a25.667 25.667 0 0 0 25.638 25.638h168.725a25.667 25.667 0 0 0 25.637-25.638V196.732a25.667 25.667 0 0 0-25.637-25.637Z"
          fill="#fff"
        />
        <path
          d="M505.133 332.095H336.408a26.668 26.668 0 0 1-26.638-26.638V196.732a26.668 26.668 0 0 1 26.638-26.637h168.725a26.668 26.668 0 0 1 26.637 26.637v108.725a26.668 26.668 0 0 1-26.637 26.638Zm-168.725-160a24.665 24.665 0 0 0-24.638 24.637v108.725a24.665 24.665 0 0 0 24.638 24.638h168.725a24.665 24.665 0 0 0 24.637-24.638V196.732a24.665 24.665 0 0 0-24.637-24.637Z"
          fill={jacketColor}
        />
        <path
          d="M231.793 164.734a9.828 9.828 0 0 0-8.043 11.1 9.428 9.428 0 0 0 .375 1.513l-50.306 44.336 18.633 13.052 44.46-51.79a9.801 9.801 0 0 0 5.852-10.438 9.38 9.38 0 0 0-10.717-7.817q-.127.02-.254.044Z"
          fill={skinTone}
        />
        <path
          d="M212.71 214.905s-11.35 13.07-25.17 27.87c-.96 1.02-1.92 2.05-2.91 3.09-8.4 8.9-17.45 18.14-25.3 25.31-8.37 7.67-15.38 13.01-18.78 13.14a2.466 2.466 0 0 1-.84-.09c-10.17-3.06-1.79.58-6.14 6.79-4.37 6.22-3.83-1.94-13.62-6.79-9.77-4.85-4.57-.03-8.47 7.63-1.21 2.4-11.83 1.91-25.83.11-1.58-13.35-3.88-24.87-3.88-24.87a113.619 113.619 0 0 1 2.44-23.83c24.39-.44 44.56-.69 45.59-.37 2.66.81 8.38-1.24 14.7-4.81.9-.51 2.6-1.69 4.85-3.35 1.99-1.46 4.39-3.28 7.06-5.32 3-2.32 8.11-3.59 9.77-7.63 4.82-11.66 3.17-2.96 4.46-3.5 1.47-.62 4.07-.86 5.59-1.75a2.01 2.01 0 0 0 1.17-2.21 4.067 4.067 0 0 0-.38-1.09c-1.38-2.77.72-3.04 2.7-3.33a5.14 5.14 0 0 0 2.33-.69c7.95-6.34 13.97-11.21 13.97-11.21l2.16 2.2.01.01 12.78 12.94Z"
          fill={jacketColor}
        />
        <path
          d="M201.808 587.867H75.43c-5.41 0-9.811-5.556-9.811-12.384V441.195c0-6.829 4.401-12.385 9.81-12.385h126.379c5.41 0 9.81 5.556 9.81 12.385v134.288c0 6.828-4.4 12.384-9.81 12.384Z"
          fill="#f2f2f2"
        />
        <path
          d="M0 587.592a1.37 1.37 0 0 0 1.19 1.503h532.29a1.543 1.543 0 0 0 0-3.005H1.19A1.37 1.37 0 0 0 0 587.592Z"
          fill="#ccc"
        />
        <path
          fill={skinTone}
          d="m289.565 569.244 12.741-.001 6.062-49.148-18.806.001.003 49.148z"
        />
        <path
          d="m289.116 582.537 39.183-.002v-.495a15.252 15.252 0 0 0-15.25-15.251h-.002l-7.157-5.43-13.354 5.43h-3.42Z"
          fill="#2f2e41"
        />
        <path
          fill={skinTone}
          d="m210.422 563.385 12.265 3.452 19.156-45.666-18.103-5.096-13.318 47.31z"
        />
        <path
          d="m206.388 576.058 37.717 10.619.134-.477a15.252 15.252 0 0 0-10.547-18.814l-5.419-7.167-14.326 1.608-3.292-.927ZM177.77 354.095s-4.08-.407 6.38 3.213c11.32 3.93 28.5 3.42 44.95 9.32 5.08 1.82 10.23 3.68 15.34 5.54 1.03.37 2.06.75 3.09 1.12 6.65 2.43 13.19 4.86 19.37 7.18.87.33 1.73.66 2.58.98.02.01.04.01.05.02 20.15 7.66 36 14.22 38.62 16.84 2.8 2.8 3.96 21.72 4.28 45.1.58 43.33-1.71 101.99-1.71 101.99l-23.57-.09s-.15-.52-.44-1.49c-.52-1.79-1.49-5.12-2.78-9.47-5.36-18.17-16.15-54.18-22.11-70.67-1.68-4.66-2.98-7.76-3.67-8.46-4-4.1 0-5.91 4-8.51s0-3.4-2.94-6.8 1.1-11.06 1.1-11.06l-6.9-.18c-6.26-.16-16-.4-25.75-.64-.01.01-.01.01-.01 0-3.67-.09-7.34-.18-10.82-.26-2.7-.07-5.29-.13-7.69-.19-7.19-.16-12.63-.27-13.99-.27-4.44 0-.99 0-3.67-3.51a15.675 15.675 0 0 0-1.33-1.49 17.322 17.322 0 0 0-2.2-2.06c-.56-.26.2 1.35-.22 3.44a6.317 6.317 0 0 1-1.55 3.03c-3.97 4.41-16.15-.35-16.15-.35-20.04 10.02-36.48 9.3-49.14 4.61a63.007 63.007 0 0 1-23.83-16.26 59.505 59.505 0 0 1-6.98-8.9l-1.65-.31s-2.22-74.383.34-79.413c3.99-7.84 63-2 63-2Z"
          fill="#2f2e41"
        />
        <path
          d="M287.686 53.095h81.433a1 1 0 0 0 0-2h-81.433a1 1 0 0 0 0 2Z"
          fill={jacketColor}
        />
        <path
          d="M397.937 132.622c-16.51-21.961-26.054-46.52-26.366-74.518a6.041 6.041 0 0 1 3.675-7.702l56.278-19.912a6.041 6.041 0 0 1 7.702 3.676l26.366 74.518a6.041 6.041 0 0 1-3.676 7.701l-56.277 19.913a6.041 6.041 0 0 1-7.702-3.676Z"
          fill="#e6e6e6"
        />
        <path
          d="M394.29 108.587c-9.93-10.04-14.18-27.948-16.95-47.907a5.658 5.658 0 0 1 3.442-7.213l48.366-17.113a5.658 5.658 0 0 1 7.213 3.443l23.462 66.31a5.658 5.658 0 0 1-3.443 7.213l-29.961 10.602a25.202 25.202 0 0 1-32.129-15.335Z"
          fill="#fff"
        />
        <path
          d="m437.008 75.519-37.675 13.448a2.4 2.4 0 1 1-1.614-4.521l37.675-13.448a2.4 2.4 0 1 1 1.614 4.52ZM403.196 73.611l-8.288 2.959a2.4 2.4 0 0 1-1.614-4.521l8.288-2.959a2.4 2.4 0 1 1 1.614 4.521ZM444.103 95.394l-37.676 13.449a2.4 2.4 0 0 1-1.614-4.521l37.676-13.449a2.4 2.4 0 1 1 1.614 4.521ZM440.556 85.457 402.88 98.905a2.4 2.4 0 0 1-1.614-4.521l37.676-13.449a2.4 2.4 0 0 1 1.614 4.522Z"
          fill={accentColor}
        />
        <circle cx={374.77} cy={56.095} r={10} fill={accentColor} />
        <path
          d="M193.855 34.095h-81.433a1 1 0 0 1 0-2h81.433a1 1 0 0 1 0 2Z"
          fill={jacketColor}
        />
        <path
          d="M7.115 69.566c7.073-26.55 20.491-49.224 42.317-66.764A6.041 6.041 0 0 1 57.759.936l50.422 31.958a6.041 6.041 0 0 1 1.866 8.328l-42.316 66.763a6.041 6.041 0 0 1-8.328 1.867L8.982 77.893a6.041 6.041 0 0 1-1.867-8.327Z"
          fill="#e6e6e6"
        />
        <path
          d="M23.764 51.852C25.527 37.841 36.984 23.437 50.97 8.93a5.658 5.658 0 0 1 7.8-1.748L102.1 34.648a5.658 5.658 0 0 1 1.748 7.799l-37.656 59.41a5.658 5.658 0 0 1-7.799 1.748L31.55 86.591a25.202 25.202 0 0 1-7.786-34.739Z"
          fill="#fff"
        />
        <path
          d="M76.157 65.02 42.309 43.7a2.4 2.4 0 0 1 2.558-4.062l33.848 21.322a2.4 2.4 0 1 1-2.558 4.062ZM56.771 37.252l-7.446-4.69a2.4 2.4 0 1 1 2.558-4.063l7.447 4.691a2.4 2.4 0 1 1-2.559 4.062ZM64.908 82.877 31.06 61.555a2.4 2.4 0 1 1 2.559-4.062l33.848 21.322a2.4 2.4 0 0 1-2.559 4.062ZM70.532 73.949 36.685 52.627a2.4 2.4 0 1 1 2.558-4.062l33.848 21.322a2.4 2.4 0 0 1-2.559 4.062Z"
          fill={accentColor}
        />
        <circle cx={106.77} cy={37.095} r={10} fill={accentColor} />
        <circle cx={321.77} cy={177.095} r={10} fill={accentColor} />
        <path
          d="M274.959 426.977c-4.17 22.72-21.84 75.97-32.52 107.14-4.87 14.24-5.27 8.88-5.27 8.88l-5.55-1.75-9.36-2.96-3.42-1.08-4.14-1.31s-2.03-36.22-2.68-63.39c-.22-8.76-.6-15.02-1.23-16.26-2.6-5.1 1.75-5.64 6.34-6.93 4.6-1.29 1.01-3.23-.78-7.37-1.21-2.76 1.17-6.43 2.86-8.53a20.7 20.7 0 0 1 1.49-1.69s-11.7-3.98-24.93-8.47c-.01.01-.01 0-.01 0-1.33-.45-2.67-.9-4.01-1.36 4.66-1.68 7.76-2.98 8.46-3.67 4.1-4 5.91 0 8.51 4s3.4 0 6.8-2.94 11.06 1.1 11.06 1.1l.18-6.9c.16-6.26.4-16 .64-25.75-.01-.01-.01-.01 0-.01.09-3.67.18-7.34.26-10.82 7.05 5.4 13.89 10.7 20.09 15.6.01.01.03.02.03.04 14.91 11.78 26.1 21.24 27.67 24.24.71 1.36.47 4.97-.49 10.19Z"
          fill="#2f2e41"
        />
        <path
          d="m178.77 342.095-3.68 2.39-9.42 6.12-12.78 8.29-68.12 44.2s-8.42-17.16-.71-32.08a33.023 33.023 0 0 0 2.41-5.91c3.12-10.7-2.88-15.34-2.88-15.34s-10-9.73-.41-18.7c1.97-1.84 3.07-5.61 3.57-10.45.84-7.96.07-18.8-1.1-28.65-1.58-13.35-3.88-24.87-3.88-24.87a113.619 113.619 0 0 1 2.44-23.83c1.83-8.44 4.77-16.46 9.12-23.12a43.979 43.979 0 0 1 5.49-6.88 35.552 35.552 0 0 1 9.95-7.17c5.56-2.68 15.09-1.07 23.49.1 1.69.23 3.33.45 4.88.61a43.678 43.678 0 0 0 4.63.29l3.48 12.7 1.32 4.81.66 2.41 1.5 5.48.62 2.24 9.98 36.44 8.15 29.72 4.5 16.42 6.73 24.55Z"
          fill={jacketColor}
        />
        <circle cx={123.018} cy={167.995} r={30.355} fill={skinTone} />
        <path
          d="m117.332 193.086-2.472 7.906c-5.483-2.706-11.04-5.462-15.605-9.53s-8.096-9.68-8.312-15.79c-.112-3.156.652-6.34.128-9.454-.592-3.518-2.764-6.569-3.758-9.996a15.945 15.945 0 0 1 8.601-18.405c5.032-2.211 10.786-1.59 16.256-2.125s11.572-3.009 13.112-8.285c.576-1.973.51-4.299 2.272-5.59a4.312 4.312 0 0 1 5.71.825c2.576 2.925 1.942 6.776 1.267 10.278a25.68 25.68 0 0 0 17.752-10.793 9.948 9.948 0 0 1 .393 14.133 7.672 7.672 0 0 0 7.465-5.887c2.095 2.894 4.245 5.911 4.919 9.42s-.577 7.656-3.794 9.209a12.327 12.327 0 0 1-5.168.846l-13.487.256a15.448 15.448 0 0 0-15.153 15.245c-3.924-.377-7.965-.746-11.676.907a8.961 8.961 0 0 0-4.165 3.578c-2.452 4.213-.019 8.59 2.137 12.422 1.844 3.275 3.59 7.219 2.061 10.652"
          fill={hairColor}
        />
        <path
          d="m255.902 501.298-56.278-19.913a6.041 6.041 0 0 1-3.675-7.701l26.366-74.518a6.041 6.041 0 0 1 7.702-3.676l56.277 19.912a6.041 6.041 0 0 1 3.676 7.702c-.313 27.998-9.856 52.557-26.366 74.518a6.041 6.041 0 0 1-7.702 3.676Z"
          fill="#e6e6e6"
        />
        <path
          d="M235.122 488.922 205.16 478.32a5.658 5.658 0 0 1-3.443-7.213l23.463-66.31a5.658 5.658 0 0 1 7.213-3.443l48.366 17.113a5.658 5.658 0 0 1 3.442 7.213c-2.77 19.959-7.02 37.867-16.95 47.907a25.202 25.202 0 0 1-32.13 15.335Z"
          fill="#fff"
        />
        <path
          d="M223.079 437.451a2.403 2.403 0 0 1 3.067-1.453l37.676 13.448a2.4 2.4 0 1 1-1.614 4.52l-37.676-13.447a2.403 2.403 0 0 1-1.453-3.068ZM256.89 435.544a2.403 2.403 0 0 1 3.068-1.454l8.289 2.959a2.4 2.4 0 1 1-1.614 4.521l-8.289-2.959a2.403 2.403 0 0 1-1.453-3.067ZM215.984 457.327a2.403 2.403 0 0 1 3.067-1.454l37.676 13.449a2.4 2.4 0 1 1-1.614 4.52l-37.675-13.448a2.403 2.403 0 0 1-1.454-3.067ZM219.531 447.39a2.403 2.403 0 0 1 3.068-1.455l37.675 13.449a2.4 2.4 0 1 1-1.613 4.52l-37.676-13.447a2.403 2.403 0 0 1-1.454-3.068Z"
          fill={accentColor}
        />
        <path
          d="M238.842 396.574a9.828 9.828 0 0 0-11.939-6.736 9.429 9.429 0 0 0-1.46.544L175.7 345.415l-10.86 19.991 56.488 38.314a9.801 9.801 0 0 0 11.032 4.634 9.38 9.38 0 0 0 6.554-11.532q-.034-.124-.072-.248Z"
          fill={skinTone}
        />
        <path
          d="m201.73 364.795-1.94 2.4-11.42 14.17-1.54 1.92s-16.31-11.2-33.94-24.39c-21.16-15.85-44.22-34.58-43.19-40.29 1.89-10.45-.78-1.72-7.45-5.34-6.66-3.63 1.5-4.02 5.21-14.29 3.72-10.26-.48-4.55-8.53-7.55-5.37-2-2.56-47.88-.11-78.16a35.552 35.552 0 0 1 9.95-7.17c6.67-3.22 19.08-.25 28.37.71 5.26 38.77 12.85 95.48 12.51 97.27-.52 2.73 2.18 8.18 6.44 14.06 2 2.78 12.31 13.35 22.62 23.73 11.5 11.58 23.02 22.93 23.02 22.93Z"
          fill={jacketColor}
        />
        <path
          d="M446.91 222.067H341.034a2.283 2.283 0 1 1 0-4.567H446.91a2.283 2.283 0 0 1 0 4.567Z"
          fill={accentColor}
        />
        <path
          d="M500.507 242.94H341.034a2.283 2.283 0 1 1 0-4.566h159.473a2.283 2.283 0 0 1 0 4.567ZM500.51 259.245H341.03a2.285 2.285 0 0 0 0 4.57h159.48a2.285 2.285 0 0 0 0-4.57ZM500.507 284.689H341.034a2.283 2.283 0 1 1 0-4.566h159.473a2.283 2.283 0 0 1 0 4.566Z"
          fill="#f2f2f2"
        />
      </svg>
    );
  }
}

export default Resume;
