import React, { Component } from "react";

// TODO: recolor to match theme

class FeelingScience extends Component {
  render() {
    const theme = this.props.theme;
    const liquidColor = theme.imageHighlight;
    const pantsColor = theme.dark;
    const shirtColor = theme.imageHighlight;
    const jacketColor = theme.jacketColor;
    const testTube = theme.compImgHighlight;
    const testTubeLining = theme.compImgHighlight;
    const skinTone = "#ffdbac"; // ffb6b6
    const hairColor = "#8d5524"; // theme.dark
    const paperColor = "#f7f7f7";

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        width="800" //637.748
        height="542.828"
        viewBox="0 0 637.748 542.828"
      >
        <path
          d="M330.64 80.54a10.132 10.132 0 0 0 14.527 5.511l87.876 28.188 29.084-22.818-113.349-19.847a10.187 10.187 0 0 0-18.137 8.966Z"
          fill={skinTone}
        />
        <path
          d="M493.406 118.209s-.797-1.952-11.312 2.296c-13.052 5.274-82.537 1.748-86.388-17.198a7.96 7.96 0 0 1-9.66 1.376l10.862-23.842c5.654-1.807 8.726-3.145 9.092-.842l17-1 68.85 11.59 1.903 23.766Z"
          fill={jacketColor}
        />
        <path
          fill={jacketColor}
          d="m471.753 94.306 34.192-2.96L538 105.999l18 249-70.638-32.668-30.307 8.385 16.698-236.41z"
        />
        <path
          fill={skinTone}
          d="m555.874 528.874-12.969-.001-6.17-50.025 19.141.001-.002 50.025z"
        />
        <path
          d="m559.182 541.446-41.82-.002v-.529a16.278 16.278 0 0 1 16.278-16.277l7.64-5.795 14.252 5.796h3.65Z"
          fill={pantsColor}
        />
        <path
          fill={skinTone}
          d="m505.615 520.701-12.651 2.856-17.038-47.437 18.672-4.216 11.017 48.797z"
        />
        <path
          d="m511.61 532.235-40.791 9.21-.117-.515a16.278 16.278 0 0 1 12.292-19.463l6.175-7.336 15.18 2.515 3.56-.804ZM572.3 223.047c6.081 18.34 5.438 38.246-1.461 59.611l-2.95 120.294c4.876 5.366 5.622 53.38-1.889 69.047-10.03 20.921-.806 23.718-7.896 37.598H537.54l-9.505-62.881-.677-50.969c-2.731-6.466-.49-29.225-.49-29.225l-.789-47.19L489 401.998c16.799 14.379 15.287 60.826 15.564 81.427l5.508 24.31-25.31 2.262-11.17-32.967-12.557-20.315c-6.4-35.996-8.297-62.422-2.84-73.216l37.221-138.486Z"
          fill={pantsColor}
        />
        <path
          d="m574.935 216.926-3.39-6.78c-4.15-25.71-8.25-49.69-2.68-71.26.35-18.83-5.47-36.52-21.63-46.18l-7.29-4.36-3.69-9.36-33.98.83-.1 12.27-12.17 19.41v.08l.76 105.62 3.36 10.9-5.07 12.68 2.69 8.09 66.99-10.71 7.79-1.25a13.918 13.918 0 0 0 2.59-1.13 14.232 14.232 0 0 0 6.22-6.95c.07-.17.13-.33.19-.5a13.934 13.934 0 0 0 .9-4.3 14.303 14.303 0 0 0-1.49-7.1Z"
          fill={shirtColor}
        />
        <path
          d="M576.425 224.026a13.934 13.934 0 0 1-.9 4.3l-6.41 7.45a13.918 13.918 0 0 1-2.59 1.13l-7.79 1.25 11.27-21.19-24.77-54.23Z"
          opacity={0.2}
        />
        <path
          d="M50.127 443.127a148.84 148.84 0 0 1-23.52-1.628c-7.248-1.226-10.923-2.967-10.923-5.176l.003-.04 13.521-102.95-6.81-19.522a5.409 5.409 0 0 1 5.164-7.19l44.784.467a5.409 5.409 0 0 1 5.075 7.118l-6.375 19.125 13.522 102.952c.003 5.412-21.648 6.844-34.44 6.844Z"
          fill={testTube}
        />
        <path
          fill="#ccc"
          d="M24.602 428.635h21.876v1.23H24.602zM24.602 421.254h21.876v1.23H24.602zM24.602 413.874h21.876v1.23H24.602zM24.602 406.493h21.876v1.23H24.602zM24.602 399.112h21.876v1.23H24.602z"
        />
        <path
          d="M246.947 443.127a148.84 148.84 0 0 1-23.52-1.628c-7.248-1.226-10.924-2.967-10.924-5.176l.003-.04 13.522-102.95-6.81-19.522a5.409 5.409 0 0 1 5.163-7.19l44.785.467a5.409 5.409 0 0 1 5.074 7.118l-6.375 19.125 13.522 102.952c.003 5.412-21.647 6.844-34.44 6.844Z"
          fill={testTube}
        />
        <path
          fill="#ccc"
          d="M221.422 428.635h21.876v1.23h-21.876zM221.422 421.254h21.876v1.23h-21.876zM221.422 413.874h21.876v1.23h-21.876zM221.422 406.493h21.876v1.23h-21.876zM221.422 399.112h21.876v1.23h-21.876z"
        />
        <path
          d="M154.688 443.127a148.84 148.84 0 0 1-23.52-1.628c-7.248-1.226-10.924-2.967-10.924-5.176l.003-.04 13.522-102.95-6.81-19.522a5.409 5.409 0 0 1 5.163-7.19l44.785.467a5.409 5.409 0 0 1 5.074 7.118l-6.375 19.125 13.522 102.952c.003 5.412-21.647 6.844-34.44 6.844Z"
          fill={testTube}
        />
        <path
          fill="#ccc"
          d="M129.163 428.635h21.876v1.23h-21.876zM129.163 421.254h21.876v1.23h-21.876zM129.163 413.874h21.876v1.23h-21.876zM129.163 406.493h21.876v1.23h-21.876zM129.163 399.112h21.876v1.23h-21.876z"
        />
        <path
          d="M300.15 450.777h-41.825V380.66h41.825a8.61 8.61 0 0 0 0-17.221H8.61a8.61 8.61 0 0 0 0 17.221h35.674v70.117H8.611a8.61 8.61 0 0 0 0 17.222H300.15a8.61 8.61 0 1 0 0-17.222Zm-238.644 0V380.66h179.598v70.117Z"
          fill="#ccc"
        />
        <path
          d="M293.377 541.561c-21.392 0-41.344-1.382-56.178-3.892-18.3-3.095-27.197-7.594-27.197-13.751l.01-.195 32.12-244.578-16.08-46.099a14.157 14.157 0 0 1 1.89-13.075 13.998 13.998 0 0 1 11.801-5.968l106.488 1.11a14.325 14.325 0 0 1 13.441 18.854l-15.053 45.16 32.126 244.6v.095c.003 6.64-10.069 11.353-30.79 14.411-20.358 3.004-43.551 3.328-52.578 3.328Z"
          fill={paperColor}
        />
        <path
          d="M293.377 541.561c-21.392 0-41.344-1.382-56.178-3.892-18.3-3.095-27.197-7.594-27.197-13.751l.01-.195 32.12-244.578-16.08-46.099a14.157 14.157 0 0 1 1.89-13.075 13.998 13.998 0 0 1 11.801-5.968l106.488 1.11a14.325 14.325 0 0 1 13.441 18.854l-15.053 45.16 32.126 244.6v.095c.003 6.64-10.069 11.353-30.79 14.411-20.358 3.004-43.551 3.328-52.578 3.328Zm-53.928-324.635a11.127 11.127 0 0 0-9.131 4.752 11.263 11.263 0 0 0-1.504 10.404l16.31 46.754-.045.345-32.157 244.832c.006 2.399 3.222 7.128 24.765 10.77 14.678 2.485 34.455 3.853 55.69 3.853 18.664 0 38.16-1.233 52.15-3.297 17.872-2.637 28.167-6.8 28.29-11.429L341.63 278.842l15.267-45.8a11.4 11.4 0 0 0-10.696-15.004l-106.504-1.11a9.7 9.7 0 0 0-.248-.002Z"
          fill={testTubeLining}
        />
        <path
          d="M354.08 493.993H232.673l19.246-130.138.524-3.587 8.018-54.177c.489-3.305 4.293-.739 6.984-2.72 16.702-12.3 33.604-12.656 50.653-.801 2.635 1.832 6.275-.312 6.767 2.86l8.423 54.341.814 5.256Z"
          fill={liquidColor}
          opacity={0.5}
        />
        <path
          d="M339.096 512.216h-91.568a16.706 16.706 0 0 1-16.526-19.151l26.753-180.91a5.309 5.309 0 0 1 8.586-3.346c16.305 13.062 34.075 12.767 52.816-.87a5.212 5.212 0 0 1 5.102-.594 5.298 5.298 0 0 1 3.226 4.107l28.12 181.5a16.707 16.707 0 0 1-16.51 19.264Z"
          fill={liquidColor}
        />
        <path
          fill={paperColor}
          d="M250.227 491.007h52.024v2.925h-52.024zM250.227 473.454h52.024v2.925h-52.024zM250.227 455.902h52.024v2.925h-52.024zM250.227 438.349h52.024v2.925h-52.024zM250.227 420.796h52.024v2.925h-52.024zM336.556 374.75a39.42 39.42 0 0 1-30.936 11.322c-6.466-.585-12.36-2.999-18.036-6.041-5.865-3.145-11.54-6.904-18.035-8.66-5.69-1.535-12.433-1.74-17.318 2.063a2.374 2.374 0 0 1-2.809.073l.556-3.803c5.441-3.803 12.053-4.388 18.533-3.086 13.047 2.633 22.95 13.427 36.319 14.978a35.034 35.034 0 0 0 30.848-12.404l.015-.015Z"
        />
        <path
          d="M637.748 541.638a1.186 1.186 0 0 1-1.19 1.19H1.268a1.19 1.19 0 0 1 0-2.38h635.29a1.187 1.187 0 0 1 1.19 1.19Z"
          fill="#ccc"
        />
        <path
          fill={jacketColor}
          d="m505.753 91.306 34.192-2.96L572 102.999l18 249-70.638-32.668-30.307 8.385 16.698-236.41z"
        />
        <circle cx={337} cy={84.999} r={6} fill={liquidColor} />
        <circle cx={327} cy={157.999} r={6} fill={liquidColor} />
        <circle cx={295} cy={200.999} r={6} fill={liquidColor} />
        <path
          d="M444.786 253.486v-64.41a4.923 4.923 0 0 1 4.917-4.916h48.643a4.923 4.923 0 0 1 4.917 4.917v64.41a4.923 4.923 0 0 1-4.917 4.917h-48.643a4.923 4.923 0 0 1-4.917-4.918Z"
          fill="#e6e6e6"
        />
        <path
          d="M448.517 234.032v-41.408a4.61 4.61 0 0 1 4.605-4.605h41.805a4.61 4.61 0 0 1 4.605 4.605v57.315a4.61 4.61 0 0 1-4.605 4.605H469.03a20.536 20.536 0 0 1-20.513-20.512Z"
          fill={paperColor}
        />
        <path
          d="M455.286 204.177a4.505 4.505 0 0 1 4.5-4.5h29a4.5 4.5 0 0 1 0 9h-29a4.505 4.505 0 0 1-4.5-4.5ZM455.286 218.177a4.505 4.505 0 0 1 4.5-4.5h29a4.5 4.5 0 0 1 0 9h-29a4.505 4.505 0 0 1-4.5-4.5ZM455.286 232.177a4.505 4.505 0 0 1 4.5-4.5h29a4.5 4.5 0 0 1 0 9h-29a4.505 4.505 0 0 1-4.5-4.5Z"
          fill="#e6e6e6"
        />
        <path
          d="M484.413 264.598a10.132 10.132 0 0 0 4.668-14.818l35.27-32.04 12.373-47.862-21.959-1.827-6.712 37.558-33.632 41.396a10.187 10.187 0 0 0 9.992 17.593Z"
          fill={skinTone}
        />
        <path
          d="M540.108 126.654s5.352.08 2.941 11.16c-1.93 8.872-9.252 58.797-15.095 69.966a7.96 7.96 0 0 1 1.927 9.567l-24.425-9.476c-2.13-5.542 1.44-7.264 3.718-7.761l-.447-17.381 3.502-44.304 24.011-11.897Z"
          fill={jacketColor}
        />
        <path
          d="M547.302 44.636a31.647 31.647 0 0 1-62.715-8.535l.062-.45a31.647 31.647 0 0 1 62.644 8.984Z"
          fill={skinTone}
        />
        <path
          d="M539.248 60.936c1.625-3.3 3.294-6.845 2.838-10.496s-4.038-7.141-7.554-6.056c-2.102.649-3.691 2.725-5.889 2.831-3.024.146-4.758-3.315-5.699-6.192l-3.833-11.72a31.708 31.708 0 0 1-26.335 5.952c-3.56-.814-7.155-2.4-9.243-5.396s-2.075-7.641.788-9.906c1.404-1.11 3.273-1.532 4.615-2.717a5.171 5.171 0 0 0-4.13-9l9.653-1.206-2.876-5.2c2.072 1.633 4.956 1.852 7.553 1.385s5.043-1.537 7.583-2.25c12.196-3.423 26.37 2.529 32.466 13.633a12.356 12.356 0 0 1 13.472 3.89c2.584 3.28 3.286 7.656 3.386 11.83a51.324 51.324 0 0 1-5.231 23.774 19.477 19.477 0 0 1-4.396 6.235 8.797 8.797 0 0 1-7.06 2.309"
          fill={hairColor}
        />
      </svg>
    );
  }
}

export default FeelingScience;
